import {
  UseMutationResult,
  useMutation,
  useQueryClient,
} from '@tanstack/react-query'
import { useSession } from 'next-auth/react'
import { customerContactsQueryKey } from 'reactQuery/queryKeys'
import customToast from 'utils/customToast'

/**
 * Client side mutation to update a contact.
 *
 * @param {string} contactId The Id of the contact to delete.
 * @returns {Promise<Response>} The server response.
 */
const deleteContactMutationFn = async (contactId: string) => {
  return await fetch('/api/contact/delete', {
    method: 'DELETE',
    body: JSON.stringify({ id: contactId }),
  })
}

/** Custom model for the mutation variables. */
interface IDeleteContactMutationVariables {
  contactId: string
  /** The message to display on success. */
  successMessageText: string
  /** The message to display on error. */
  errorMessageText: string
}

/**
 * Mutation to update a contact.
 *
 * @returns {UseMutationResult<void>} The mutation result.
 */
const useDeleteContactMutation = (): UseMutationResult<
  boolean | undefined,
  void,
  IDeleteContactMutationVariables
> => {
  const { data: session } = useSession()
  const queryClient = useQueryClient()

  return useMutation({
    mutationFn: async (variables) => {
      try {
        const response = await deleteContactMutationFn(variables.contactId)
        if (response.status !== 200) {
          throw new Error('could not delete contact.')
        }
        customToast.success(variables.successMessageText)
        return true
      } catch (_error) {
        customToast.error(variables.errorMessageText)
      }
    },
    onSettled: () => {
      if (session?.user?.id) {
        queryClient.invalidateQueries(customerContactsQueryKey(session.user.id))
      }
    },
  })
}

export { useDeleteContactMutation, deleteContactMutationFn }
