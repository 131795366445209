// customToast.js
import { toast } from 'react-toastify'
import SuccessIcon from 'assets/icons/v2/success-icon.svg'
import WarningIcon from 'assets/icons/v2/warning-icon.svg'
import AlertIcon from 'assets/icons/v2/alert-icon.svg'
import FeedbackOverlay from 'app/_components/FeedbackOverlay/FeedbackOverlay'

const customToast = {
  success(msg, options = {}) {
    return toast.success(msg, {
      className: 'toast-message toast-success  ',
      icon: <SuccessIcon />,
      closeButton: false,
      toastId: 'successToastId',
      ...options,
    })
  },
  error(msg, options = {}) {
    return toast.error(msg, {
      className: 'toast-message  toast-error',
      icon: <AlertIcon />,
      closeButton: false,
      ...options,
    })
  },
  warn(msg, options = {}) {
    return toast.warn(msg, {
      className: 'toast-message toast-info',
      closeButton: false,
      icon: <WarningIcon />,
      ...options,
    })
  },
  warnBanner(msg, options = {}) {
    return toast(<FeedbackOverlay alertText={msg} type="warn" />, {
      closeButton: false,
      autoClose: false,
      icon: false,
      toastId: 'warnBannerId',
      style: { background: 'transparent', padding: 0 },
      ...options,
    })
  },
}
export default customToast
