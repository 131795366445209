import {
  UseMutationResult,
  useMutation,
  useQueryClient,
} from '@tanstack/react-query'
import { ITaxInformation } from 'models/customer-data-service/taxInformation'
import { useSession } from 'next-auth/react'
import { customerContractQueryKey } from 'reactQuery/queryKeys'
import customToast from 'utils/customToast'
import { HttpMethod } from 'utils/types'

interface IMutationFnParams {
  contractId: string
  taxInformation: ITaxInformation
}

/**
 * Client site mutation to update the users tax information.
 *
 * @returns {Promise<Response>}  The server response.
 */
const updateTaxInformationMutationFn = async (
  params: IMutationFnParams
): Promise<Response> => {
  return await fetch('api/contract/updateTaxInformation', {
    method: HttpMethod.PATCH,
    body: JSON.stringify(params),
  })
}

interface IMutationVars {
  data: IMutationFnParams
  successMessage: string
  errorMessage: string
  /** The additional message to warn the user to change the bank data with the grid operator as well*/
  notifyGridOperatorMessage: string
}

/**
 * Mutation to update tax information.
 *
 * @returns {UseMutationResult<ITaxInformation>} The mutation result, the updated info if successful, undefined if not.
 */
const useUpdateTaxInformationMutation = (): UseMutationResult<
  ITaxInformation | undefined,
  void,
  IMutationVars
> => {
  const { data: session } = useSession()
  const queryClient = useQueryClient()

  return useMutation({
    mutationFn: async (vars) => {
      try {
        const response = await updateTaxInformationMutationFn(vars.data)
        if (response.status !== 200) {
          throw new Error('could not update tax information.')
        }
        const responseJson = await response.json()
        customToast.success(vars.successMessage)
        customToast.warnBanner(vars.notifyGridOperatorMessage)
        return responseJson.data as ITaxInformation
      } catch {
        customToast.error(vars.errorMessage)
      }
    },
    onSettled: () => {
      if (session?.user?.id) {
        queryClient.invalidateQueries(customerContractQueryKey(session.user.id))
      }
    },
  })
}

export { useUpdateTaxInformationMutation }
