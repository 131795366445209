export type WithNavProp = {
  _withNav: boolean
}

export type WithContactWidgetProp = {
  _withContactWidget: boolean
}

export type BlurImageProps = {
  src: string
  width: number
  height: number
  blurDataURL: string
  type?: string
}

export type BlurImagePropsLayoutFill = {
  src: string
  blurDataURL: string
  type?: string
}

export type FormInputType = 'text' | 'tel' | 'email' | 'date'

export type RecursivePartial<T> = {
  [P in keyof T]?: RecursivePartial<T[P]>
}

export enum HttpMethod {
  GET = 'GET',
  POST = 'POST',
  PATCH = 'PATCH',
  DELETE = 'DELETE',
}
