import {
  UseMutationResult,
  useMutation,
  useQueryClient,
} from '@tanstack/react-query'
import { useSession } from 'next-auth/react'
import { customerContactsQueryKey } from 'reactQuery/queryKeys'
import customToast from 'utils/customToast'
import { HttpMethod } from 'utils/types'

/**
 * Client side mutation to delete a contact.
 *
 * @param {string} contactId The Id of the contact to delete.
 * @returns {Promise<Response>} The server response.
 */
const contactDeleteMutationFn = async (
  contactId: string
): Promise<Response> => {
  return await fetch('/api/contact/deleteContact', {
    method: HttpMethod.DELETE,
    body: JSON.stringify(contactId),
  })
}

/** Custom model for the mutation variables. */
interface IContactDeleteMutationVariables {
  /** The id to send with the request. */
  contactId: string
}

interface IContactDeleteMutationParams {
  /** The message to display on success. */
  successMessageText: string
  /** The message to display on error. */
  errorMessageText: string
}

/**
 * Mutation to update a contact.
 *
 * @returns {UseMutationResult<void>} The mutation result.
 */
const useContactDeleteMutation = ({
  successMessageText,
  errorMessageText,
}: IContactDeleteMutationParams): UseMutationResult<
  boolean | undefined,
  void,
  IContactDeleteMutationVariables
> => {
  const { data: session } = useSession()
  const queryClient = useQueryClient()

  return useMutation({
    mutationFn: async ({ contactId }: IContactDeleteMutationVariables) => {
      const response = await contactDeleteMutationFn(contactId)
      console.log({ response })
      if (response.status !== 200) {
        throw new Error('could not delete contact.')
      }
      return true
    },
    onSettled: () => {
      if (session?.user?.id) {
        queryClient.invalidateQueries(customerContactsQueryKey(session.user.id))
      }
    },
    onSuccess: () => {
      customToast.success(successMessageText)
    },
    onError() {
      customToast.error(errorMessageText)
    },
  })
}

export { useContactDeleteMutation }
