import {
  UseMutationResult,
  useMutation,
  useQueryClient,
} from '@tanstack/react-query'
import {
  IPaymentDetails,
  IUpdatePaymentDetails,
} from 'models/customer-data-service/paymentDetails'
import { useSession } from 'next-auth/react'
import { customerContractQueryKey } from 'reactQuery/queryKeys'
import customToast from 'utils/customToast'
import { HttpMethod } from 'utils/types'

/**
 * Client side mutation to create a contact.
 *
 * @param {{contractId: string, paymentDetailsUpdate: IUpdatePaymentDetails}} , The model of the contractId and payment details to update.
 * @returns {Promise<Response>} The server response.
 */
const updatePaymentDetailsMutationFn = async ({
  contractId,
  paymentDetailsUpdate,
}: {
  contractId: string
  paymentDetailsUpdate: IUpdatePaymentDetails
}): Promise<Response> => {
  return await fetch('api/contract/updatePaymentDetails', {
    method: HttpMethod.PATCH,
    body: JSON.stringify({ contractId, paymentDetailsUpdate }),
  })
}

/** Custom model for the mutation variables. */
interface IPaymentDetailsMutationVariables {
  /** The id to send with the request. */
  contractId: string
  /** Payment details to send with the request. */
  paymentDetailsUpdate: IUpdatePaymentDetails
}
interface IPaymentDetailsMutationParams {
  /** The message to display on success. */
  successMessageText: string
  /** The message to display on error. */
  errorMessageText: string
}

/**
 * Mutation to update payment details
 *
 * @returns {UseMutationResult<IContact>} The mutation result.
 */
const usePaymentDetailsMutation = ({
  successMessageText,
  errorMessageText,
}: IPaymentDetailsMutationParams): UseMutationResult<
  IPaymentDetails,
  void,
  IPaymentDetailsMutationVariables
> => {
  const { data: session } = useSession()
  const queryClient = useQueryClient()

  return useMutation({
    mutationFn: async ({
      contractId,
      paymentDetailsUpdate,
    }: IPaymentDetailsMutationVariables) => {
      const response = await updatePaymentDetailsMutationFn({
        contractId,
        paymentDetailsUpdate,
      })
      if (response.status !== 200) {
        throw new Error('could not update payment details')
      }
      const updatePaymentDetails = (await response.json()) as IPaymentDetails
      return updatePaymentDetails
    },
    onSettled: () => {
      if (session?.user?.id) {
        queryClient.invalidateQueries(customerContractQueryKey(session.user.id))
      }
    },
    onSuccess: () => {
      customToast.success(successMessageText)
    },
    onError() {
      customToast.error(errorMessageText)
    },
  })
}

export { usePaymentDetailsMutation }
