'use client'

import classNames from 'classnames'
import { useState } from 'react'
import DATA_TEST_IDS from 'utils/dataTestIds'
import CloseIcon from 'assets/icons/v2/close-icon.svg'

type FeedbackOverlayProps = { alertText: string; type: 'warn' | 'error' }

const typeClassCard = {
  error: 'text-white bg-error-red hover:bg-error-hover active:bg-error-active',
  warn: 'text-enpalGray-600 bg-enpal-darkmode-yellow',
}
const typeClassBtn = {
  warn: 'stroke-enpalGray-600',
}

const FeedbackOverlay = ({ alertText, type }: FeedbackOverlayProps) => {
  const [openFeedbackOverlay, setOpenFeedbackOverlay] = useState(true)

  return openFeedbackOverlay ? (
    <div className="card">
      <div
        className={classNames(
          'flex feedback-overlay-container card-item w-full rounded-xl py-3 px-4 gap-3 text-left',
          typeClassCard[type]
        )}
      >
        <p>{alertText}</p>
        <button
          data-testid={
            DATA_TEST_IDS.ENERGY_CONTRACT_CANCELLATION_OR_WITHDRAW_BUTTON
          }
          className={classNames(
            'gap-2 text-left font-medium hover:cursor-pointer hover:underline ',
            typeClassBtn[type]
          )}
          onClick={() => setOpenFeedbackOverlay(false)}
        >
          <CloseIcon />
        </button>
      </div>
    </div>
  ) : (
    <></>
  )
}

export default FeedbackOverlay
