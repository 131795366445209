import { QueryClientConfig } from '@tanstack/react-query'

// Base config to use for every query client, whether it is server or client side.
const queryClientConfig: QueryClientConfig = {
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
      // This will retry failed queries 5 times before failing and return with an error.
      retry: 5,
      // This will increase the delay between retries based on the attempt index by doubling the initial retry delay.
      // Max wait time is 30 seconds.
      retryDelay: (attemptIndex) => Math.min(1000 * 2 ** attemptIndex, 30000),
    },
  },
}

export default queryClientConfig
